<template>
  <CRow>
    <CCol sm="12" lg="6">
      <CWidgetDropdown color="primary" :header="dashdata.totaldocs" text="Total de Documentos" style="padding-bottom:40px;">
        
       
      </CWidgetDropdown>
    </CCol>
    <CCol sm="12" lg="6">
      <CWidgetDropdown color="info" :header="dashdata.totalusers" text="Total de usuários" style="padding-bottom:40px;">

        
      </CWidgetDropdown>
    </CCol>

  </CRow>
</template>

<script>
import { CChartLineSimple, CChartBarSimple } from '../charts/index.js'

export default {
  props:['dashboard'],
  name: 'WidgetsDropdown',
  components: { CChartLineSimple, CChartBarSimple },
  computed:{
      dashdata() {
        return this.dashboard
      }
  }
}
</script>
