<template>
  <div>
    

   
  
  </div>
</template>

<script>
import MainChartExample from './charts/MainChartExample'
import WidgetsDropdown from './widgets/WidgetsDropdown'
import WidgetsBrand from './widgets/WidgetsBrand'


export default {
  name: 'Dashboard',
  components: {
    MainChartExample,
    WidgetsDropdown,
    WidgetsBrand
  },
  data () {
    return {
        dashboard:false
    }
  },
  computed:{
     userdata() {
       return {
         'usertype': this.$store.getters.getUsertype,
         'client': this.$store.getters.getCompany,
         'username': this.$store.getters.getUsername
       }
     }
  },
  mounted(){
     
  },  
  methods: {

  }
}
</script>
